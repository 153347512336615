<template>
  <div
      class="relative flex items-center"
      :class="isCollapsed ? 'flex-col' : 'gap-[0px]'"
  >
    <div
        class="flex items-center gap-[0px] text-[#000] hover:text-gray-400 transition duration-100 ease-in-out"
        :class="isCollapsed ? 'justify-center flex-col' : 'justify-start'"
    >
      <SpotIcon
          :class="isCollapsed ? 'w-[22px] h-[30px]' : 'w-[20px] h-[20px] mr-1.5'"
      />
      <span
          :class="isCollapsed ? 'text-[10px]' : 'text-[16px]'"
          class="relative"
      >
        {{ $t("articleroute.spot") }}
      </span>
    </div>
  </div>

<!--    <div-->
<!--      class="absolute top-[38px] right-[20px] text-[7px] font-[300] text-[#FF6663]"-->
<!--      :class="-->
<!--        isCollapsed-->
<!--          ? 'top-[38px] right-[20px] translate-x-1/2'-->
<!--          : '-top-2 -right-1'-->
<!--      "-->
<!--    >-->
<!--      скоро-->
<!--    </div>-->
</template>

<script setup>
import { useI18n } from "vue-i18n";
import SpotIcon from "./icons/SpotIcon.vue";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
</script>

<template>
  <svg width="16" height="12" viewBox="0 -2 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3025_13224)">
      <path d="M11.75 1.125L8 4.875L4.25 1.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
    </g>
    <defs>
      <filter id="filter0_d_3025_13224" x="0.5" y="0.375" width="15" height="11.25" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3025_13224"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3025_13224" result="shape"/>
      </filter>
    </defs>
  </svg>

</template>
<script setup lang="ts">
</script>
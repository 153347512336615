<template>
  <svg
    width="11"
    height="16"
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.48476 15.9314C5.45652 15.9742 5.40863 16 5.35734 16C5.30605 16 5.25816 15.9742 5.22992 15.9314L0.302553 8.45982C0.271643 8.41292 0.268971 8.35285 0.29557 8.3034C0.322168 8.25394 0.3738 8.22311 0.429973 8.22311H2.9051V1.07018C2.9051 0.985885 2.97345 0.917539 3.05775 0.917539H7.65654C7.74084 0.917539 7.80919 0.985885 7.80919 1.07018V8.22311H10.2847C10.3409 8.22311 10.3925 8.25394 10.4191 8.3034C10.4457 8.35285 10.443 8.41292 10.4121 8.45982L5.48476 15.9314Z"
      fill="white"
    />
  </svg>
</template>

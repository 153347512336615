<template>
<!--  <svg-->
<!--    width="30"-->
<!--    height="30"-->
<!--    viewBox="0 0 30 30"-->
<!--    fill="none"-->
<!--    xmlns="http://www.w3.org/2000/svg"-->
<!--  >-->
<!--    <path-->
<!--      d="M15.25 23.5C17.5221 23.4999 19.7298 22.7451 21.5264 21.3544C23.3231 19.9636 24.607 18.0155 25.1765 15.8159M15.25 23.5C12.9779 23.4999 10.7702 22.7451 8.97355 21.3544C7.17688 19.9636 5.89298 18.0155 5.32345 15.8159M15.25 23.5C18.0801 23.5 20.375 18.9103 20.375 13.25C20.375 7.58972 18.0801 3 15.25 3M15.25 23.5C12.4199 23.5 10.125 18.9103 10.125 13.25C10.125 7.58972 12.4199 3 15.25 3M25.1765 15.8159C25.3872 14.9959 25.5 14.1361 25.5 13.25C25.5028 11.4871 25.0488 9.7536 24.1823 8.21839M25.1765 15.8159C22.1393 17.4996 18.7227 18.3804 15.25 18.375C11.6488 18.375 8.2652 17.4468 5.32345 15.8159M5.32345 15.8159C5.10791 14.9777 4.99923 14.1155 5 13.25C5 11.4221 5.47834 9.70464 6.3177 8.21839M15.25 3C17.0679 2.99924 18.8533 3.48216 20.423 4.3992C21.9927 5.31624 23.2902 6.63438 24.1823 8.21839M15.25 3C13.4321 2.99924 11.6466 3.48216 10.0769 4.3992C8.50725 5.31624 7.2098 6.63438 6.3177 8.21839M24.1823 8.21839C21.7026 10.3663 18.5306 11.5464 15.25 11.5417C11.8356 11.5417 8.71278 10.2889 6.3177 8.21839"-->
<!--      stroke="none"-->
<!--      stroke-width="1.5"-->
<!--      stroke-linecap="round"-->
<!--      stroke-linejoin="round"-->
<!--      class="mobile:stroke-[#797979] stroke-white"-->
<!--    />-->
<!--  </svg>-->


  <svg width="28" height="28" viewBox="0 -2 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3025_13084)">
      <path d="M13 19C14.995 18.9999 16.9335 18.3372 18.511 17.116C20.0886 15.8948 21.2159 14.1843 21.716 12.253M13 19C11.005 18.9999 9.06654 18.3372 7.48898 17.116C5.91141 15.8948 4.78408 14.1843 4.284 12.253M13 19C15.485 19 17.5 14.97 17.5 10C17.5 5.03 15.485 1 13 1M13 19C10.515 19 8.50001 14.97 8.50001 10C8.50001 5.03 10.515 1 13 1M21.716 12.253C21.901 11.533 22 10.778 22 10C22.0025 8.45211 21.6039 6.92999 20.843 5.582M21.716 12.253C19.0492 13.7314 16.0492 14.5048 13 14.5C9.83801 14.5 6.867 13.685 4.284 12.253M4.284 12.253C4.09475 11.517 3.99933 10.76 4 10C4 8.395 4.42 6.887 5.157 5.582M13 1C14.5962 0.999335 16.1639 1.42336 17.5422 2.22856C18.9205 3.03377 20.0597 4.19117 20.843 5.582M13 1C11.4038 0.999335 9.83608 1.42336 8.45781 2.22856C7.07954 3.03377 5.94031 4.19117 5.157 5.582M20.843 5.582C18.6657 7.46793 15.8805 8.50414 13 8.5C10.002 8.5 7.26001 7.4 5.157 5.582" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
    </g>
    <defs>
      <filter id="filter0_d_3025_13084" x="0.25" y="0.25" width="25.5" height="25.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3025_13084"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3025_13084" result="shape"/>
      </filter>
    </defs>
  </svg>

</template>

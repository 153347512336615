<template>
  <article
    class="rounded-r-lg mobile:rounded-none w-[306px] mobile:h-[150%] mobile:mr-0 mobile:w-[306px] mobilemini:w-[280px] mr-[5px] shadow bg-white pk:w-[210px] pk:mr-[5px]"
  >
    <ArticleBalance
      v-if="user"
      @toggle-balance="$emit('toggle-balance')"
      @open-modal="handleModalOpen"
      class="pt-[30px] pk:pt-[15px] mobile:pt-[20px] mobilemini:px-[10px] pk:pb-0"
    />
    <ArticleMethods v-if="user" @navigation="handleNavigation" />
    <div class="">
      <div
        v-if="!user"
        class="text-center pb-[10px] pt-[20px] items-center flex flex-col gap-[6px]"
      >
        <Entry
          class="text-[#797979] stroke-[#797979] justify-center"
          :showSlot="true"
          @open-login="openLogin"
          @open-register="openRegister"
        />
        <TelegramEntry
          @click="() => openRegisterGoogleTelegram('telegram')"
          :showSlotReg="true"
          class="mb-2 !w-[194px] !text-[11px] !justify-start !gap-x-[11px]"
        />
        <GoogleEntry
          @click="() => openRegisterGoogleTelegram('google')"
          :showSlotReg="true"
          class="mb-2 !w-[194px] !text-[11px] !justify-start !gap-x-[11px]"
        />
      </div>
      <ArticleChat
        class="text-[16px] text-[#797979]"
        @navigation="handleNavigation"
      />
      <ArticleMarket
        class="text-[16px] text-[#797979]"
        @navigation="handleNavigation"
      />
      <ArticleTrading
        class="text-[16px] text-[#797979]"
        @navigation="handleNavigation"
      />
      <ArticleGame
        class="text-[16px] text-[#797979]"
        @navigation="handleNavigation"
      />
    </div>
    <div class="flex w-full px-[40px]"><Language class="hidden mobile:block" /></div>
  </article>
</template>

<script setup>
import { watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/userStore";
import { useAuthFormsStore } from "@/stores/authFormsStore";
import ArticleBalance from "../components/ArticleBalance.vue";
import ArticleChat from "../components/ArticleChat.vue";
import ArticleMethods from "../components/ArticleMethods.vue";
import ArticleMarket from "../components/ArticleMarket.vue";
import ArticleTrading from "../components/ArticleTrading.vue";
import ArticleGame from "../components/ArticleGame.vue";
import Entry from "../components/auth/Entry.vue";
import GoogleEntry from "../components/auth/GoogleEntry.vue";
import TelegramEntry from "@/components/auth/TelegramEntry.vue";
import Language from "../components/Language.vue";

const emit = defineEmits(["navigation-event", "update-user", "toggle-balance"]);

const router = useRouter();
const userStore = useUserStore();
const authFormsStore = useAuthFormsStore();
const { user } = storeToRefs(userStore);

watch(
  () => router.currentRoute.value,
  () => {
    emit("navigation-event");
    authFormsStore.close();
  }
);

const handleNavigation = () => {
  emit("navigation-event");
};

const handleModalOpen = () => {
  emit("navigation-event");
};

const openLogin = () => {
  authFormsStore.updateFormState({
    currentForm: "login",
    isVisible: true,
  });
};

const openRegister = () => {
  authFormsStore.updateFormState({
    currentForm: "register",
    isVisible: true,
  });
};

const openRegisterGoogleTelegram = (type) => {
  authFormsStore.showGoogleTelegramRegistration(type);
  emit("navigation-event");
};
</script>

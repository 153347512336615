<template>
  <div
    class="flex flex-col items-center cursor-pointer"
    :class="containerClass"
  >
    <div class="flex items-center mobile:text-[12px]">
      <EntrySvg class="mobile:w-[16px] mobile:h-[16px]" />
      <p>
        <span
          @click.stop="openLoginForm"
          class="hover:border-b hover:border-current"
          >{{ $t("login.enter") }}</span
        >
        |
        <span
          @click.stop="openRegisterForm"
          class="hover:border-b hover:border-current"
          >{{ $t("login.registration") }}</span
        >
      </p>
    </div>
    <slot v-if="showSlot">
      <p class="text-[12px] mobile:text-[10px]">
        {{ $t("login.regclick") }}
      </p>
    </slot>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import EntrySvg from "@/components/icons/Entry-svg.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

defineProps({
  showSlot: {
    type: Boolean,
    default: false,
  },
  containerClass: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["openLogin", "openRegister"]);

const openLoginForm = (event) => {
  console.log("openLoginForm clicked, preventing default");
  event.preventDefault();
  console.log("Emitting openLogin event");
  emit("openLogin");
};

const openRegisterForm = (event) => {
  console.log("openRegisterForm clicked, preventing default");
  event.preventDefault();
  console.log("Emitting openRegister event");
  emit("openRegister");
};
</script>

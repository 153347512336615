<template>
  <div
    class="fixed bottom-0 left-0 w-full bg-[#E1E1E1]/50 text-[#797979] font-[300] text-[12px] px-5 overflow-hidden z-50"
  >
    <div class="flex animate-ticker">
      <div
        v-for="crypto in cryptoTickers"
        :key="crypto.ticker"
        class="flex items-center mr-6 min-w-max"
      >
        <component
          :is="currenciesStore.cryptoIcons[crypto.ticker]"
          class="h-4 w-4 mr-1"
        />
        <span class="mr-1 font-medium">{{ crypto.ticker }}/USDT</span>
        <span class="text-green-500"
          :class="{
            'text-green-500': crypto.priceChange >= 0,
            'text-red-500': crypto.priceChange < 0,
          }"
        >
          {{ formatAmount(crypto.price) }} ({{
            typeof crypto.priceChange === "number"
              ? crypto.priceChange.toFixed(2)
              : "0.00"
          }}%)
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useCurrenciesStore } from "@/stores/currenciesStore";
import { useRateStore } from "@/stores/rateStore";
import { useCurrencySelectionStore } from "@/stores/currencySelectionStore";

const currenciesStore = useCurrenciesStore();
const rateStore = useRateStore();
const currencySelectionStore = useCurrencySelectionStore();

const cryptoTickers = ref([]);
let rateUpdateInterval = null;

const formatAmount = (amount) => {
  if (!amount || amount === 0) return "0";
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const fetchCryptoTickers = () => {
  const selectedCurrency = "USDT";
  const cryptoList = currenciesStore.getAllCryptoCurrencies;

  cryptoTickers.value = cryptoList.map((crypto) => {
    const priceChange = rateStore.getPriceChangeFromFixed(
      crypto.ticker,
      selectedCurrency
    );
    return {
      ticker: crypto.ticker,
      price:
        rateStore.cryptoRates?.fiat?.[crypto.ticker]?.[selectedCurrency] || 0,
      priceChange: typeof priceChange === "number" ? priceChange : 0,
    };
  });
};

onMounted(() => {
  Promise.all([rateStore.fetchAllRates(), currenciesStore.fetchCurrencies()])
    .then(() => {
      fetchCryptoTickers();
    })
    .catch((error) => {
      console.error("Failed to fetch initial data:", error);
    });

  rateUpdateInterval = setInterval(() => {
    rateStore
      .fetchAllRates()
      .then(() => {
        fetchCryptoTickers();
      })
      .catch((error) => {
        console.error("Failed to fetch rates:", error);
      });
  }, 5000);
});

onUnmounted(() => {
  if (rateUpdateInterval) {
    clearInterval(rateUpdateInterval);
    rateUpdateInterval = null;
  }
});
</script>

<style scoped>
/* @keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-ticker {
  display: inline-flex;
  animation: ticker 20s linear infinite;
} */
</style>

<template>
  <div
    class="flex items-center gap-[0px]"
    :class="isCollapsed ? 'justify-center flex-col' : 'justify-start'"
  >
    <DailyTaskIcon
      :class="isCollapsed ? 'w-[22px] h-[30px]' : 'w-[20px] h-[20px] mr-1.5'"
    />
    <span class="text-center" :class="isCollapsed ? 'text-[10px] text-center mb-[3px]' : ''">{{
      $t("articleroute.dailyTasks")
    }}</span>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import DailyTaskIcon from "./icons/DailyTaskIcon.vue";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
</script>

<template>
  <div class="relative">
    <div
      @click="toggleDropdown"
      class="flex flex-col mobile:flex-row mobile:gap-[5px] items-center justify-start cursor-pointer hover:opacity-80"
    >
      <LanguageIcon class="w-[30px] mobile:w-[32px]" />
      <span
        class="mobile:hidden text-[10px] mobile:text-[14px] mobile:text-[#797979] custom-shadow tablet:hidden"
        >{{ languages[currentLanguage] }}</span
      >
<!--      <span class="text-[14px] text-[#797979] mobile:block">{{-->
<!--        languages[currentLanguage]-->
<!--      }}</span>-->
    </div>

    <!-- Дропдаун -->
    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-if="isOpen"
        class="absolute right-0 mt-2 py-2 w-32 bg-white rounded-lg shadow-lg z-50"
      >
        <div
          v-for="(label, code) in languages"
          :key="code"
          @click="changeLanguage(code)"
          class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
          :class="{ 'bg-gray-50': currentLanguage === code }"
        >
          <span
            class="w-6 h-6 rounded-full border flex items-center justify-center text-[13px]"
            :class="{
              'bg-[#fc7a78] border-[#fc7a78] text-white':
                currentLanguage === code,
            }"
          >
            {{ code.toUpperCase().slice(0, 2) }}
          </span>
          <span>{{ label }}</span>
        </div>
      </div>
    </Transition>

    <!-- Backdrop для закрытия дропдауна -->
    <div v-if="isOpen" class="fixed inset-0 z-40" @click="closeDropdown"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import LanguageIcon from "../components/icons/LanguageIcon.vue";

const { locale } = useI18n();
const isOpen = ref(false);

const languages = {
  ru: "Русский",
  en: "English",
  zh: "中文",
  ja: "日本語",
};

const currentLanguage = ref(localStorage.getItem("language") || "en");

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const closeDropdown = () => {
  isOpen.value = false;
};

const changeLanguage = (lang) => {
  currentLanguage.value = lang;
  locale.value = lang;
  localStorage.setItem("language", lang);
  closeDropdown();
};

const handleEscKey = (e) => {
  if (e.key === "Escape" && isOpen.value) {
    closeDropdown();
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleEscKey);
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleEscKey);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7692 5.63723C16.4946 5.87026 17 6.56277 17 7.35785V10.8746C17 11.8067 16.305 12.5976 15.3754 12.674C15.0964 12.6961 14.8174 12.7166 14.5385 12.733V15.2692L12.0769 12.8077C10.9659 12.8077 9.86646 12.7626 8.77846 12.674C8.54161 12.6548 8.3112 12.5872 8.10154 12.4754M15.7692 5.63723C15.6424 5.59643 15.5114 5.57027 15.3787 5.55929C13.1813 5.37687 10.9726 5.37687 8.77518 5.55929C7.84718 5.63641 7.15385 6.42657 7.15385 7.35785V10.8746C7.15385 11.5613 7.53128 12.171 8.10154 12.4754M15.7692 5.63723V4.09959C15.7692 2.76954 14.824 1.61672 13.5046 1.44523C11.8068 1.22517 10.0966 1.11499 8.38462 1.11539C6.64923 1.11539 4.9401 1.2278 3.26462 1.44523C1.94523 1.61672 1 2.76954 1 4.09959V9.20811C1 10.5382 1.94523 11.691 3.26462 11.8625C3.73805 11.924 4.21395 11.9773 4.69231 12.0216V15.8846L8.10154 12.4754"
      stroke="#797979"
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.75 10.0094C19.75 15.3942 15.3848 19.7595 10 19.7595C4.61522 19.7595 0.25 15.3942 0.25 10.0094C0.25 4.62464 4.61522 0.259399 10 0.259399C15.3848 0.259399 19.75 4.62464 19.75 10.0094Z" fill="#FFEBEB" stroke="#FFA563" stroke-width="0.5"/>
    <path d="M14.9879 8.25699H5.0957V14.2784H14.9879V8.25699Z" fill="#99A1CB"/>
    <path d="M14.9879 10.8376H5.0957V12.1279H14.9879V10.8376Z" fill="#C7A659"/>
    <path d="M11.1173 8.25711H8.9668V14.2785H11.1173V8.25711Z" fill="#FFD572"/>
    <path d="M15.4184 6.7518H4.66602V8.47219H15.4184V6.7518Z" fill="#4D5166"/>
    <path d="M9.80255 10.1927L8.94235 9.54758L8.51226 10.1927C7.89179 9.32406 7.99026 8.13414 8.7451 7.37929L8.94235 7.18204H10.2326L10.0354 7.37929C9.28055 8.13414 9.18208 9.32406 9.80255 10.1927Z" fill="#BF4C53"/>
    <path d="M10.2797 10.1927L11.1399 9.54758L11.57 10.1927C12.1905 9.32406 12.092 8.13414 11.3372 7.37929L11.1399 7.18204H9.84961L10.0469 7.37929C10.8017 8.13414 10.9002 9.32406 10.2797 10.1927Z" fill="#BF4C53"/>
    <path d="M12.2637 7.7225L10.4727 7.39686V5.67647L12.2637 5.35083C12.5628 5.29645 12.8382 5.52626 12.8382 5.83031V7.24305C12.8382 7.54708 12.5628 7.77691 12.2637 7.7225Z" fill="#E65C64"/>
    <path d="M7.82059 7.72251L9.61162 7.39687V5.67648L7.82059 5.35084C7.52146 5.29643 7.24609 5.52627 7.24609 5.83029V7.24303C7.24609 7.54708 7.52146 7.77692 7.82059 7.72251Z" fill="#E65C64"/>
    <path d="M10.3989 7.61192H9.68438C9.52538 7.61192 9.39648 7.48302 9.39648 7.32402V5.74932C9.39648 5.59032 9.52538 5.46143 9.68438 5.46143H10.3989C10.5579 5.46143 10.6868 5.59032 10.6868 5.74932V7.32402C10.6868 7.48302 10.5579 7.61192 10.3989 7.61192Z" fill="#592427"/>
  </svg>



</template>

<template>
  <div
    class="relative flex items-center"
    :class="isCollapsed ? 'flex-col' : 'gap-[0px]'"
  >
    <div
      class="flex items-center gap-[0px] text-[#797979] cursor-not-allowed"
      :class="isCollapsed ? 'justify-center flex-col' : 'justify-start'"
    >
      <FujersIcon
        :class="isCollapsed ? 'w-[22px] h-[30px]' : 'w-[22px] h-[30px] mr-1.5'"
      />
      <span :class="isCollapsed ? 'text-[10px]' : 'text-[16px]'">
        {{ $t("articleroute.futures") }}
      </span>
    </div>

    <div
      class="absolute top-[22px] right-[20px] text-[7px] font-[300] text-[#FF6663]"
      :class="
        isCollapsed
          ? 'top-[22px] right-[20px] translate-x-1/2'
          : '-top-2 -right-1'
      "
    >
      скоро
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import FujersIcon from "./icons/FujersIcon.vue";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
</script>

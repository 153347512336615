<template>
  <div
    class="flex items-center gap-[0px]"
    :class="isCollapsed ? 'justify-center flex-col' : 'justify-start'"
  >
    <MemCoinIcon
      :class="isCollapsed ? 'w-[22px] h-[30px]' : 'w-[20px] h-[20px] mr-1.5'"
    />
    <span :class="isCollapsed ? 'text-[10px]' : ''" class="relative">
      Мемкоины
    </span>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import MemCoinIcon from "./icons/MemCoinIcon.vue";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
</script>

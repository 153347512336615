<template>
  <div
    class="relative wrap flex justify-end items-center lg:!pl-[6px] "
    ref="dropdownRef"
  >
    <div
      class="flex items-center cursor-pointer wrap-in"
      @click="toggleDropdown"
    >
      <span class="text-[12px] desktop:text-[10px] font-[400] text-[#000]">
        {{ currencySelectionStore.selectedCurrency }}
      </span>
      <span
        class="ml-[2px] transition-transform duration-300"
        :class="{ 'rotate-180': isOpen }"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.75 4.125L6 7.875L2.25 4.125"
            stroke="#797979"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
    <div
      v-if="isOpen"
      class="absolute wrap-in top-[20px] bg-white rounded-md shadow z-10 w-[50px] pb-0"
    >
      <div class="max-h-[200px] h-full overflow-y-auto custom-scrollbar">
        <div
          v-for="currency in limitedCurrencies"
          :key="currency.ticker"
          class="flex items-center justify-center p-1 cursor-pointer hover:bg-gray-100 rounded-md text-[10px]"
          @click="selectCurrency(currency.ticker)"
        >
          <span>{{ currency.ticker }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useCurrencySelectionStore } from "@/stores/currencySelectionStore";
import { useCurrenciesStore } from "@/stores/currenciesStore";
import { useRateStore } from "@/stores/rateStore";

const currencySelectionStore = useCurrencySelectionStore();
const currenciesStore = useCurrenciesStore();
const rateStore = useRateStore();
const isOpen = ref(false);
const dropdownRef = ref(null);

const PRIMARY_CURRENCIES = ["RUB", "EUR", "USD", "AED"];

const limitedCurrencies = computed(() => {
  const allCurrencies = currenciesStore.getAllFiatCurrencies;
  return allCurrencies.slice(0, 4);
});

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function selectCurrency(code) {
  currencySelectionStore.setCurrency(code);
  rateStore.fetchAllRates();
  isOpen.value = false;
}

function handleClickOutside(event) {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isOpen.value = false;
  }
}

const modifiedCurrencyStore = {
  ...currencySelectionStore,
  getPricePerCoin: (cryptoTicker) => {
    const selectedCurrency = currencySelectionStore.selectedCurrency;

    if (PRIMARY_CURRENCIES.includes(selectedCurrency)) {
      return (
        currencySelectionStore.rates.value?.fiat?.[cryptoTicker]?.[
          selectedCurrency
        ] || 0
      );
    }

    const usdPrice =
      currencySelectionStore.rates.value?.fiat?.[cryptoTicker]?.USD || 0;
    const usdToSelectedRate =
      currencySelectionStore.rates.value?.fiat?.USD?.[selectedCurrency] || 1;

    return usdPrice * usdToSelectedRate;
  },

  convertCryptoToFiat: (amount, cryptoTicker, fiatTicker) => {
    if (!currencySelectionStore.rates.value) return 0;

    if (PRIMARY_CURRENCIES.includes(fiatTicker)) {
      const directRate =
        currencySelectionStore.rates.value.fiat[cryptoTicker]?.[fiatTicker];
      return amount * (directRate || 0);
    }

    const usdPrice =
      currencySelectionStore.rates.value.fiat[cryptoTicker]?.USD || 0;
    const usdToFiatRate =
      currencySelectionStore.rates.value.fiat.USD?.[fiatTicker] || 1;

    return amount * usdPrice * usdToFiatRate;
  },
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<template>
  <div
    class="relative flex items-center"
    :class="isCollapsed ? 'flex-col' : 'gap-[0px]'"
  >
    <button
      class="flex items-center gap-[0px]"
      :class="isCollapsed ? 'justify-center flex-col' : 'justify-start'"
    >
      <StakingIcon
        :class="isCollapsed ? 'w-[22px] h-[30px]' : 'w-[20px] h-[20px] mr-1.5'"
      />
      <span :class="isCollapsed ? 'text-[10px]' : ''">{{
        $t("articleroute.staking")
      }}</span>
    </button>

<!--    <div-->
<!--      class="bg-[#4ADE80]/40 text-black rounded-full px-2 py-[2px] text-[9px] font-[500]"-->
<!--      :class="isCollapsed ? 'mt-[0px]' : 'ml-1'"-->
<!--    >-->
<!--      10% APR-->
<!--    </div>-->
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import StakingIcon from "./icons/StakingIcon.vue";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
</script>

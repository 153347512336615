<template>
<!--  <svg-->
<!--    width="14"-->
<!--    height="15"-->
<!--    viewBox="0 0 14 15"-->
<!--    fill="none"-->
<!--    xmlns="http://www.w3.org/2000/svg"-->
<!--  >-->
<!--    <path-->
<!--      d="M7 9.34749C7.46413 9.34749 7.90925 9.1551 8.23744 8.81264C8.56563 8.47018 8.75 8.00571 8.75 7.5214C8.75 7.03709 8.56563 6.57262 8.23744 6.23016C7.90925 5.8877 7.46413 5.69531 7 5.69531C6.53587 5.69531 6.09075 5.8877 5.76256 6.23016C5.43437 6.57262 5.25 7.03709 5.25 7.5214C5.25 8.00571 5.43437 8.47018 5.76256 8.81264C6.09075 9.1551 6.53587 9.34749 7 9.34749Z"-->
<!--      fill="#fff"-->
<!--    />-->
<!--    <path-->
<!--      fill-rule="evenodd"-->
<!--      clip-rule="evenodd"-->
<!--      d="M0.771753 7.18513C1.63975 4.46365 4.09967 2.5 7.00059 2.5C9.89975 2.5 12.3585 4.46183 13.2277 7.18087C13.2977 7.40122 13.2977 7.63861 13.2277 7.85835C12.3603 10.5798 9.89975 12.5435 6.99942 12.5435C4.10025 12.5435 1.64092 10.5817 0.772337 7.86261C0.70219 7.64271 0.70219 7.40502 0.772337 7.18513H0.771753ZM10.0625 7.52174C10.0625 8.36928 9.73985 9.18211 9.16552 9.78141C8.59119 10.3807 7.81223 10.7174 7 10.7174C6.18778 10.7174 5.40882 10.3807 4.83449 9.78141C4.26016 9.18211 3.9375 8.36928 3.9375 7.52174C3.9375 6.6742 4.26016 5.86137 4.83449 5.26207C5.40882 4.66277 6.18778 4.32609 7 4.32609C7.81223 4.32609 8.59119 4.66277 9.16552 5.26207C9.73985 5.86137 10.0625 6.6742 10.0625 7.52174Z"-->
<!--      fill="#fff"-->
<!--    />-->
<!--  </svg>-->
    <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6.75C10.4641 6.75 10.9092 6.56563 11.2374 6.23744C11.5656 5.90925 11.75 5.46413 11.75 5C11.75 4.53587 11.5656 4.09075 11.2374 3.76256C10.9092 3.43437 10.4641 3.25 10 3.25C9.53587 3.25 9.09075 3.43437 8.76256 3.76256C8.43437 4.09075 8.25 4.53587 8.25 5C8.25 5.46413 8.43437 5.90925 8.76256 6.23744C9.09075 6.56563 9.53587 6.75 10 6.75Z" fill="white"/>
      <g filter="url(#filter0_d_3461_2876)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.77078 4.67742C4.63878 2.06933 7.09869 0.1875 9.99961 0.1875C12.8988 0.1875 15.3575 2.06758 16.2267 4.67333C16.2967 4.8845 16.2967 5.112 16.2267 5.32258C15.3593 7.93067 12.8988 9.8125 9.99844 9.8125C7.09928 9.8125 4.63994 7.93242 3.77136 5.32667C3.70121 5.11593 3.70121 4.88815 3.77136 4.67742H3.77078ZM13.0615 5C13.0615 5.81223 12.7389 6.59118 12.1645 7.16551C11.5902 7.73984 10.8113 8.0625 9.99903 8.0625C9.1868 8.0625 8.40784 7.73984 7.83351 7.16551C7.25918 6.59118 6.93653 5.81223 6.93653 5C6.93653 4.18777 7.25918 3.40882 7.83351 2.83449C8.40784 2.26016 9.1868 1.9375 9.99903 1.9375C10.8113 1.9375 11.5902 2.26016 12.1645 2.83449C12.7389 3.40882 13.0615 4.18777 13.0615 5Z" fill="white"/>
      </g>
      <defs>
        <filter id="filter0_d_3461_2876" x="0.71875" y="0.1875" width="18.5605" height="15.625" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3461_2876"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3461_2876" result="shape"/>
        </filter>
      </defs>
    </svg>
</template>

<template>
  <div :class="isCollapsed ? '' : 'border-b'" class="pt-[15px] pb-[0px]">
    <h2
      class="flex items-center text-[14px] pk:text-[12px] text-nowrap"
      :class="
        isCollapsed
          ? 'justify-center py-[0px]'
          : 'mb-[12px] justify-start pl-[45px] pk:pl-[25px]'
      "
    >
      <span
        class="flex w-full items-center"
        :class="[
          isCollapsed
            ? 'flex-col cursor-pointer justify-center transition duration-300 ease-in-out hover:text-gray-500 relative'
            : '',
          isCollapsed && route.name === 'social-network'
            ? 'bg-gray-400 text-[#000]'
            : 'text-[#000]',
        ]"
        @click="isCollapsed ? navigateTo('social-network') : null"
      >
        <div class="relative">
          <ChatIconArticle
            :class="
              isCollapsed ? 'w-[22px] h-[30px]' : 'w-[20px] h-[20px] mr-1.5'
            "
          />
          <span
            v-if="isCollapsed"
            class="absolute -top-[5px] shadow -right-[10px] bg-[#0098EA] text-white rounded-full w-[18px] h-[18px] flex items-center justify-center text-[10px] font-[500]"
          >
            99
          </span>
        </div>
        <span v-if="!isCollapsed" :class="isCollapsed ? '' : 'text-[14px]'"
          >Мессенджер</span
        >
        <span v-else :class="isCollapsed ? 'font-[300] text-[10px]' : ''">
          Сообщения
        </span>
      </span>
    </h2>
    <ul
      v-if="!isCollapsed"
      class="flex flex-col items-start justify-between font-[300] text-[14px] pk:text-[12px]"
    >
      <li class="text-nowrap w-full">
        <div
          @click="navigateTo('social-network')"
          class="pl-[65px] w-full pk:pl-[40px] transition duration-300 ease-in-out py-[3px] hover:bg-gray-100 cursor-pointer"
          :class="
            route.name === 'social-network'
              ? 'bg-gray-100 text-[#000]'
              : 'text-[#000]'
          "
        >
          <ArticleChatMess :isCollapsed="isCollapsed" />
        </div>
      </li>
      <li class="text-nowrap w-full">
        <div
          @click="navigateTo('social-channel')"
          class="pl-[65px] w-full pk:pl-[40px] transition duration-300 ease-in-out py-[3px] hover:bg-gray-100 cursor-pointer"
          :class="
            route.name === 'social-channel'
              ? 'bg-gray-100 text-[#000]'
              : 'text-[#000]'
          "
        >
          <ArticleChatChanel :isCollapsed="isCollapsed" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import ArticleChatSett from "./ArticleChatSett.vue";
import ArticleChatMess from "./ArticleChatMess.vue";
import ArticleChatChanel from "./ArticleChatChanel.vue";
import ChatIconArticle from "./icons/ChatIconArticle.vue";
import { useI18n } from "vue-i18n";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["navigation"]);

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const navigateTo = (name) => {
  router.push({ name: name }).catch((err) => {
    if (err.name !== "NavigationDuplicated") {
      throw err;
    }
  });
  emit("navigation");
};
</script>
